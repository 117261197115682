import React, { useRef } from "react";
import moment from 'moment'
import { GatsbyImage } from "gatsby-plugin-image"
import { Zoom, Fade } from "react-awesome-reveal";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { sliderSettings1 } from '../const/attr'
import { labels, localeUrls, days } from '../langs/langs'
import { isInTheFuture } from "../functions/functions";

import ArrowLink from "./ArrowLink";

import eventI from '../images/events.svg'
import calendarI from '../images/calendar.svg'

const EventSlider = ({ localeID, data }) => {

    const slider = useRef();

    const events = data.nodes.filter((item)=>isInTheFuture(item.acfEvents.date));

    const emptyMsg = <><h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3><p className="empty">{labels[localeID]["NONEWS"]}</p></>;

    const settings = {
        ...sliderSettings1,
        slidesToShow: 1,
    }

    return (
        <div className="hPadSec infSliderCon" id="newEvents">
            <div className="content infSlider cards">
                <div className="hCon center l"><Zoom><img src={eventI}/></Zoom><h2 className="b">{labels[localeID]["NEWEVENTS"]}</h2></div>
                {!!events.length ? <Slider {...settings} ref={slider}>
                    {!!events.length && events.map((item, index)=>{
                            return <EventCard key={index} localeID={localeID} item={item}/>
                        })}
                </Slider> : emptyMsg}
                <Fade><ArrowLink to={localeUrls[localeID]["EVENTS"]} className="l big" color="#ED6E47">{labels[localeID]["SHOWALLEVENTS"]}</ArrowLink></Fade>
            </div>
        </div>
    )
}

export const EventCard = ({ item, localeID }) => <div className="eventCard">
    <div className="imgCon16">
        <GatsbyImage image={item?.featuredImage?.node?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt='test' />
    </div>
    <div className="infoBox center">
        <div>
            <p className="center"><img src={calendarI}/>
                <span className="oh">{moment(item.acfEvents.date).format('d.M.Y')} | {days(localeID)[moment(item.acfEvents.date).day()]} {item.acfEvents?.time}</span>
            </p>
            <h3>{item.title}</h3>
            <ArrowLink to={item.uri} color="#ED6E47">{labels[localeID]["MORE"]}</ArrowLink>
        </div>
    </div>
</div>

export const EventCardSmall = ({ item, localeID }) => <div className="shadow">
    <div className="imgCon16">
        <GatsbyImage image={item?.featuredImage?.node?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt='test' />
    </div>
    <div className="infoBox center">
        <div>
            <p className="center"><img src={calendarI}/>
                <span className="oh">{moment(item.acfEvents.date).format('d.M.Y')} | {days(localeID)[moment(item.acfEvents.date).day()]} {item.acfEvents?.time}</span>
            </p>
            <h3>{item.title}</h3>
            <ArrowLink to={item.uri} color="#ED6E47">{labels[localeID]["MORE"]}</ArrowLink>
        </div>
    </div>
</div>


export default EventSlider;
